import { get, post } from './ajaxutils'

export default {
  login (window, scope) {
    const encodedURI = encodeURIComponent(scope.$router.resolve(scope.$store.state.login.nextRoute || { name: 'home' }, scope.$route).href)
    window.location.href = `/login/facebook?next=${encodedURI}`
  },
  logout () {
    return post('/api/logout').then(response => response.data)
  },
  findUser (emailOrPhoneOrCpf, grtoken) {
    return post('/api/find_user', { username: emailOrPhoneOrCpf, grtoken }).then(response => response.data)
  },
  findUserViaRevendedor (emailOrPhoneOrCpf, grtoken) {
    return post('/api/find_user_via_revendedor', { username: emailOrPhoneOrCpf, grtoken }).then(response => response.data)
  },
  loginUser (userId, password, grtoken) {
    return post('/api/login', { id: userId, password, grtoken }).then(response => response.data)
  },
  loginUserNoPassword (userId) {
    return post('/api/login_no_password', { id: userId }).then(response => response.data)
  },
  userSignUp (userData, should_login = true, redirect = null) {
    const params = { user: JSON.stringify(userData), should_login }
    if (redirect) {
      params.redirect = redirect
    }
    return post('/api/signup', params).then(response => response.data)
  },
  sendLoginToken (userId) {
    return post('/api/send_login_token', { id: userId }).then(response => response.data)
  },
  forgotPassword (userId) {
    return post('/api/forgot_password', { id: userId }).then(response => response.data)
  },
  resetPassword (userId, token, password) {
    return post('/api/reset_password', { user_id: userId, token, password }).then(response => response.data)
  },
  validateResetPasswordToken (userId, token) {
    return get('/api/validate_reset_password_token', { user_id: userId, token }).then(response => response.data)
  },
  loginComToken (userId, token) {
    return post('/api/login_com_token', { user_id: userId, token }).then(response => response.data)
  },
  whoami (fields = ['search_data', 'has_pdv', 'revendedor_type']) {
    return get('/api/whoami', { fields: fields.join(',') }).then(response => response.data)
  },
  completeRegistration (params) {
    return post('/api/complete_registration', { params: JSON.stringify(params) }).then(response => response.data)
  },
  confirmPhone (code) {
    return post(`/api/confirm_phone/${code}`).then(response => response.data)
  },
  resendPhoneConfirmation (code) {
    return post('/api/resend_phone_confirmation').then(response => response.data)
  },
  invitation (invitecode) {
    return get(`/api/invitations/${invitecode}`).then(response => response.data)
  },
  confirmInvitationClaim (code, email) {
    return post('/api/confirm_invitation_claim', { code, email }).then(response => response.data)
  },
  saveUser (params) {
    return post('/api/save_user', { params: JSON.stringify(params) }).then(response => response.data)
  },
  inactivateAccount (reason) {
    return post('/api/inactivate_account', { reason }).then(response => response.data)
  },
  confirmPhoneLink (token) {
    return post('/api/confirm_phone_link', { token }).then(response => response.data)
  },
  confirmPhoneLinkSentByStaff (token) {
    return post('/api/confirm_phone_link_sent_by_staff', { token }).then(response => response.data)
  },
  loginGoogle (window, scope) {
    const encodedURI = encodeURIComponent(scope.$router.resolve(scope.$store.state.login.nextRoute || { path: '/' }, scope.$route).href)
    window.location.href = `/login/google-oauth2?next=${encodedURI}`
  },
  passengers () {
    return get('/api/me/passengers').then(response => response.data.data)
  },
  passengersFromUser (user) {
    return get(`/api/users/${user.id}/passengers`).then(response => response.data.data)
  },
  carteiraPdv (params) {
    return get('/api/vendas/carteira_pdv', params).then(response => response.data)
  },
  pagamentoPdv () {
    return get('/api/vendas/pagamento_pdv').then(response => response.data)
  },
  parcelasPdv () {
    return get('/api/vendas/parcelas_pdv').then(response => response.data)
  }
}
